export const Dollar = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.1411 13.7138H48.0036V17.1338H51.4236V20.5763H54.8661V27.4388H58.2861V44.5613H54.8661V51.4238H51.4236V54.8663H48.0036V58.2863H41.1411V61.7063H23.9961V65.1488H48.0036V61.7063H54.8661V58.2863H58.2861V54.8663H61.7286V51.4238H65.1486V44.5613H68.5686V27.4388H65.1486V20.5763H61.7286V17.1338H58.2861V13.7138H54.8661V10.2938H48.0036V6.85126H23.9961V10.2938H41.1411V13.7138Z"
        fill="#E1E1E1"
      />
      <path
        d="M54.8663 27.4388H51.4238V44.5613H54.8663V27.4388Z"
        fill="#E1E1E1"
      />
      <path
        d="M51.4234 44.5612H48.0034V48.0037H51.4234V44.5612Z"
        fill="#E1E1E1"
      />
      <path
        d="M51.4234 23.9962H48.0034V27.4387H51.4234V23.9962Z"
        fill="#E1E1E1"
      />
      <path
        d="M48.0035 48.0038H44.5835V51.4238H48.0035V48.0038Z"
        fill="#E1E1E1"
      />
      <path
        d="M48.0035 20.5763H44.5835V23.9963H48.0035V20.5763Z"
        fill="#E1E1E1"
      />
      <path
        d="M44.5836 51.4238H41.1411V54.8663H44.5836V51.4238Z"
        fill="#E1E1E1"
      />
      <path
        d="M44.5836 17.1338H41.1411V20.5763H44.5836V17.1338Z"
        fill="#E1E1E1"
      />
      <path
        d="M41.1411 54.8663H23.9961V58.2863H41.1411V54.8663Z"
        fill="#E1E1E1"
      />
      <path
        d="M41.1412 37.7213H37.7212V44.5613H41.1412V37.7213Z"
        fill="#B9EA4C"
      />
      <path
        d="M41.1412 27.4388H37.7212V30.8588H41.1412V27.4388Z"
        fill="#B9EA4C"
      />
      <path
        d="M41.1411 13.7137H23.9961V17.1337H41.1411V13.7137Z"
        fill="#E1E1E1"
      />
      <path
        d="M30.8585 48.0038V51.4238H34.2785V48.0038H37.721V44.5613H34.2785V37.7213H37.721V34.2788H34.2785V27.4388H37.721V23.9963H34.2785V20.5763H30.8585V23.9963H27.4385V27.4388H30.8585V34.2788H27.4385V37.7213H30.8585V44.5613H27.4385V48.0038H30.8585Z"
        fill="#B9EA4C"
      />
      <path
        d="M27.4386 41.1413H23.9961V44.5613H27.4386V41.1413Z"
        fill="#B9EA4C"
      />
      <path
        d="M27.4386 27.4388H23.9961V34.2788H27.4386V27.4388Z"
        fill="#B9EA4C"
      />
      <path
        d="M23.9963 58.2863H17.1562V61.7063H23.9963V58.2863Z"
        fill="#E1E1E1"
      />
      <path
        d="M23.9962 51.4238H20.5762V54.8663H23.9962V51.4238Z"
        fill="#E1E1E1"
      />
      <path
        d="M23.9962 17.1338H20.5762V20.5763H23.9962V17.1338Z"
        fill="#E1E1E1"
      />
      <path
        d="M23.9963 10.2938H17.1562V13.7138H23.9963V10.2938Z"
        fill="#E1E1E1"
      />
      <path
        d="M20.5763 48.0038H17.1562V51.4238H20.5763V48.0038Z"
        fill="#E1E1E1"
      />
      <path
        d="M20.5763 20.5763H17.1562V23.9963H20.5763V20.5763Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.1564 54.8663H13.7139V58.2863H17.1564V54.8663Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.1564 44.5612H13.7139V48.0037H17.1564V44.5612Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.1564 23.9962H13.7139V27.4387H17.1564V23.9962Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.1564 13.7137H13.7139V17.1337H17.1564V13.7137Z"
        fill="#E1E1E1"
      />
      <path
        d="M13.7135 51.4238H10.2935V54.8663H13.7135V51.4238Z"
        fill="#E1E1E1"
      />
      <path
        d="M13.7135 27.4388H10.2935V44.5613H13.7135V27.4388Z"
        fill="#E1E1E1"
      />
      <path
        d="M13.7135 17.1338H10.2935V20.5763H13.7135V17.1338Z"
        fill="#E1E1E1"
      />
      <path
        d="M10.2936 44.5612H6.85107V51.4237H10.2936V44.5612Z"
        fill="#E1E1E1"
      />
      <path
        d="M10.2936 20.5763H6.85107V27.4388H10.2936V20.5763Z"
        fill="#E1E1E1"
      />
      <path
        d="M6.85115 27.4388H3.43115V44.5613H6.85115V27.4388Z"
        fill="#E1E1E1"
      />
    </svg>
  );
};
