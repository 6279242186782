export const Heart = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_736_2576)">
        <path d="M64.4276 61.7175H61.0076V72H64.4276V61.7175Z" fill="#E1E1E1" />
        <path
          d="M61.0074 58.2975H57.5649V61.7175H61.0074V58.2975Z"
          fill="#E1E1E1"
        />
        <path
          d="M57.565 61.7175H54.145V65.1375H57.565V61.7175Z"
          fill="#E1E1E1"
        />
        <path d="M57.565 54.855H54.145V58.2975H57.565V54.855Z" fill="#E1E1E1" />
        <path
          d="M54.1451 65.1375H50.7026V68.5799H54.1451V65.1375Z"
          fill="#E1E1E1"
        />
        <path
          d="M54.145 51.4351H47.2825V54.8551H54.145V51.4351Z"
          fill="#E1E1E1"
        />
        <path
          d="M54.1451 24.0074H50.7026V34.2899H54.1451V24.0074Z"
          fill="#E1E1E1"
        />
        <path d="M50.7025 68.58H47.2825V72H50.7025V68.58Z" fill="#E1E1E1" />
        <path d="M50.7025 34.29H47.2825V37.71H50.7025V34.29Z" fill="#E1E1E1" />
        <path
          d="M50.7026 20.5649H43.8625V24.0074H50.7026V20.5649Z"
          fill="#E1E1E1"
        />
        <path
          d="M47.2825 54.855H43.8625V58.2975H47.2825V54.855Z"
          fill="#E1E1E1"
        />
        <path
          d="M47.2824 47.9926H40.4199V51.4351H47.2824V47.9926Z"
          fill="#E1E1E1"
        />
        <path
          d="M47.2825 37.71H43.8625V41.1525H47.2825V37.71Z"
          fill="#E1E1E1"
        />
        <path
          d="M43.8624 58.2975H40.4199V61.7175H43.8624V58.2975Z"
          fill="#E1E1E1"
        />
        <path
          d="M43.8624 41.1525H40.4199V44.5725H43.8624V41.1525Z"
          fill="#E1E1E1"
        />
        <path
          d="M43.8624 24.0074H40.4199V27.4274H43.8624V24.0074Z"
          fill="#E1E1E1"
        />
        <path d="M40.42 61.7175H37V65.1375H40.42V61.7175Z" fill="#E1E1E1" />
        <path d="M40.42 44.5725H37V47.9925H40.42V44.5725Z" fill="#E1E1E1" />
        <path d="M40.42 27.4275H37V30.8475H40.42V27.4275Z" fill="#E1E1E1" />
        <path
          d="M37.0001 47.9926H33.5801V51.4351H37.0001V47.9926Z"
          fill="#E1E1E1"
        />
        <path
          d="M37.0001 30.8475H33.5801V34.29H37.0001V30.8475Z"
          fill="#E1E1E1"
        />
        <path
          d="M33.58 51.4351H30.1375V54.8551H33.58V51.4351Z"
          fill="#E1E1E1"
        />
        <path d="M33.58 34.29H30.1375V37.71H33.58V34.29Z" fill="#E1E1E1" />
        <path
          d="M30.1375 37.71H26.7175V41.1525H30.1375V37.71Z"
          fill="#E1E1E1"
        />
        <path
          d="M30.1375 27.4275H26.7175V34.29H30.1375V27.4275Z"
          fill="#E1E1E1"
        />
        <path
          d="M26.7174 41.1525H23.2749V44.5725H26.7174V41.1525Z"
          fill="#E1E1E1"
        />
        <path
          d="M23.275 0V3.42H19.855V10.2825H23.275V13.725H26.7175V17.145H30.1375V20.565H33.58V17.145H37V13.725H40.42V10.2825H43.8625V3.42H40.42V0H33.58V3.42H30.1375V0H23.275Z"
          fill="#B9EA4C"
        />
        <path
          d="M26.7176 24.0074H16.4351V27.4274H26.7176V24.0074Z"
          fill="#E1E1E1"
        />
        <path
          d="M19.8551 37.71H16.4351V51.435H19.8551V47.9925H23.2751V44.5725H19.8551V37.71Z"
          fill="#E1E1E1"
        />
        <path
          d="M16.4349 51.4351H12.9924V54.8551H16.4349V51.4351Z"
          fill="#E1E1E1"
        />
        <path
          d="M16.4349 27.4275H12.9924V37.71H16.4349V27.4275Z"
          fill="#E1E1E1"
        />
        <path d="M12.9925 54.855H9.57251V72H12.9925V54.855Z" fill="#E1E1E1" />
      </g>
      <defs>
        <clipPath id="clip0_736_2576">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
