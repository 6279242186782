export const Lock = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.0533 10.4253H62.563V48.659H66.0533V10.4253Z"
        fill="#E1E1E1"
      />
      <path
        d="M62.5632 48.6591H59.0957V55.6169H62.5632V48.6591Z"
        fill="#E1E1E1"
      />
      <path
        d="M59.0954 55.6168H55.6279V59.1071H59.0954V55.6168Z"
        fill="#E1E1E1"
      />
      <path
        d="M62.5629 6.95776H55.6279V10.4253H62.5629V6.95776Z"
        fill="#E1E1E1"
      />
      <path
        d="M55.628 59.1072H52.1377V62.5747H55.628V59.1072Z"
        fill="#E1E1E1"
      />
      <path
        d="M55.628 31.2759H52.1377V52.1493H55.628V31.2759Z"
        fill="#E1E1E1"
      />
      <path
        d="M52.1379 62.5747H48.6704V66.0422H52.1379V62.5747Z"
        fill="#E1E1E1"
      />
      <path
        d="M52.1379 52.1494H48.6704V55.6169H52.1379V52.1494Z"
        fill="#E1E1E1"
      />
      <path
        d="M55.6282 3.46753H48.6704V6.95784H55.6282V3.46753Z"
        fill="#E1E1E1"
      />
      <path
        d="M48.6702 66.0422H41.7124V69.5325H48.6702V66.0422Z"
        fill="#E1E1E1"
      />
      <path
        d="M48.6705 55.6168H24.3296V59.1071H48.6705V55.6168Z"
        fill="#E1E1E1"
      />
      <path
        d="M41.7126 41.7241H38.2451V38.2337H34.7548V34.7662H31.2873V38.2337H27.8198V45.1915H31.2873V48.659H34.7548V52.1494H38.2451V48.659H41.7126V45.1915H45.203V38.2337H41.7126V41.7241Z"
        fill="#B9EA4C"
      />
      <path
        d="M45.2027 10.4253H41.7124V13.9156H45.2027V10.4253Z"
        fill="#E1E1E1"
      />
      <path
        d="M41.7126 34.7662H38.2451V38.2337H41.7126V34.7662Z"
        fill="#B9EA4C"
      />
      <path d="M41.7124 69.5325H31.2871V73H41.7124V69.5325Z" fill="#E1E1E1" />
      <path
        d="M41.7124 6.95776H31.2871V10.4253H41.7124V6.95776Z"
        fill="#E1E1E1"
      />
      <path
        d="M31.2874 66.0422H24.3296V69.5325H31.2874V66.0422Z"
        fill="#E1E1E1"
      />
      <path
        d="M31.2873 10.4253H27.8198V13.9156H31.2873V10.4253Z"
        fill="#E1E1E1"
      />
      <path d="M48.6705 0H24.3296V3.4675H48.6705V0Z" fill="#E1E1E1" />
      <path
        d="M52.1378 31.276V27.8085H48.6703V13.9156H45.2028V27.8085H27.8196V13.9156H24.3293V27.8085H20.8618V31.276H52.1378Z"
        fill="#E1E1E1"
      />
      <path
        d="M24.3293 62.5747H20.8618V66.0422H24.3293V62.5747Z"
        fill="#E1E1E1"
      />
      <path
        d="M24.3293 52.1494H20.8618V55.6169H24.3293V52.1494Z"
        fill="#E1E1E1"
      />
      <path
        d="M24.3295 3.46753H17.3945V6.95784H24.3295V3.46753Z"
        fill="#E1E1E1"
      />
      <path
        d="M20.862 59.1072H17.3945V62.5747H20.862V59.1072Z"
        fill="#E1E1E1"
      />
      <path
        d="M20.862 31.2759H17.3945V52.1493H20.862V31.2759Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.3946 55.6168H13.9043V59.1071H17.3946V55.6168Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.3943 6.95776H10.4365V10.4253H17.3943V6.95776Z"
        fill="#E1E1E1"
      />
      <path
        d="M13.904 48.6591H10.4365V55.6169H13.904V48.6591Z"
        fill="#E1E1E1"
      />
      <path
        d="M10.4366 10.4253H6.94629V48.659H10.4366V10.4253Z"
        fill="#E1E1E1"
      />
    </svg>
  );
};
