import { Markdown } from "grommet";
import styled from "styled-components";

const CONTENT = `
**Коротко о наших ценностях и правилах**  

Основные ценности  

**1. В первую очередь мы уважаем всех участников сообщества.**  

**2. Всегда исходим из добрых намерений.**  

**3. Руководствуемся здравым смыслом.**  

**4. Стараемся быть открытыми и всегда помогать другим участникам сообщества.**  

**4. При общении исключаем негатив, агрессию и недружелюбность.**  

**5. И последнее — если участник нарушает одно из основных правил сообщества мы говорим ему это напрямую, если ситуация не меняется будем использовать наш административный ресурс :)**  

Если вы разделяете те же ценности и готовы следовать нашим правилам, все что осталось это подтвердить свои намерения кнопкой ниже.


`;

export const ValuesDisclaimer = () => {
  return <StyledMD>{CONTENT}</StyledMD>;
};

export const StyledMD = styled(Markdown)`
  & > * {
    font-size: 14px;
  }
  & > p {
    margin: 20px 0;
    max-width: 100%;
  }
`;
